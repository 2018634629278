module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"sysgarage","short_name":"sysgarage","start_url":"/","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f03a9c60f4d1062881339a57521a261b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/tmp/build_cf0b62ea/src/intl","languages":["en"],"defaultLanguage":"en","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-572NQWH","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-111502980-1","head":true,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-styled-components-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"light":{"body":"#fafafa","text":"#000","moonIcon":"moon-light.svg","SGLogo":"sg-logo-black.svg","SGLogoOpen":"sg-logo-small-black.svg","headerLink":"dark","headerContactButton":"dark","homeSectionDescription":"#000","homeSectionChipText":"#fff","homeSectionButton":"dark","sliderArrowColor":"#000","footer":{"background":"#000","linkColor":"light","SGLogo":"sg-logo-small-white.svg","titleColor":"#fff","buttonColor":"light"},"reviewCard":{"background":"#fff","title":"#000","body":"#8890A1","authorJob":"#8890A1"},"buttonColor":"dark","highlightProcessBox":{"bgColor":"#000","textColor":"#fff"},"homeStaffImage":"home/light/staff-augmentation.png","homeProductImage":"home/light/product-delivery.png","notFoundImage":"home/light/notFound.png","image500":"home/light/500.png","bannerImage":"home/light/home-banner-2500x1562.png","freeTrialImage":"free-trial.png"},"dark":{"body":"#000","text":"#fff","moonIcon":"moon.svg","SGLogo":"sg-logo-white.svg","SGLogoOpen":"sg-logo-small-white.svg","headerLink":"light","headerContactButton":"light","homeSectionDescription":"#fff","homeSectionChipText":"#000","homeSectionButton":"light","sliderArrowColor":"#fff","footer":{"background":"#fff","linkColor":"dark","SGLogo":"sg-logo-small-black.svg","titleColor":"#000","buttonColor":"dark"},"reviewCard":{"background":"#1f1f1f","title":"#fff","body":"#fff","authorJob":"#fff"},"buttonColor":"light","highlightProcessBox":{"bgColor":"#fff","textColor":"#000"},"homeStaffImage":"home/dark/staff-augmentation.png","homeProductImage":"home/dark/product-delivery.png","image500":"home/dark/500.png","freeTrialImage":"free-trial-dark.png","notFoundImage":"home/dark/notFound.png","bannerImage":"home/dark/home-banner-2500x1562.png"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
