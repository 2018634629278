// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thank-you-js": () => import("./../../../src/pages/contact/thank-you.js" /* webpackChunkName: "component---src-pages-contact-thank-you-js" */),
  "component---src-pages-culture-index-js": () => import("./../../../src/pages/culture/index.js" /* webpackChunkName: "component---src-pages-culture-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-agitagym-js": () => import("./../../../src/pages/projects/agitagym.js" /* webpackChunkName: "component---src-pages-projects-agitagym-js" */),
  "component---src-pages-projects-basapp-js": () => import("./../../../src/pages/projects/basapp.js" /* webpackChunkName: "component---src-pages-projects-basapp-js" */),
  "component---src-pages-projects-dermaclinic-js": () => import("./../../../src/pages/projects/dermaclinic.js" /* webpackChunkName: "component---src-pages-projects-dermaclinic-js" */),
  "component---src-pages-projects-fudex-js": () => import("./../../../src/pages/projects/fudex.js" /* webpackChunkName: "component---src-pages-projects-fudex-js" */),
  "component---src-pages-projects-globaltill-js": () => import("./../../../src/pages/projects/globaltill.js" /* webpackChunkName: "component---src-pages-projects-globaltill-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-zyn-js": () => import("./../../../src/pages/projects/zyn.js" /* webpackChunkName: "component---src-pages-projects-zyn-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-product-team-js": () => import("./../../../src/pages/services/product-team.js" /* webpackChunkName: "component---src-pages-services-product-team-js" */),
  "component---src-pages-services-staff-augmentation-js": () => import("./../../../src/pages/services/staff-augmentation.js" /* webpackChunkName: "component---src-pages-services-staff-augmentation-js" */)
}

